import ajax from "./ajax";
import axios from "axios";
import notify from "@/utils/notify";
import store from "@/store";
import router from "@/router";

let loginId = null;
let loginPwd = null;
export default {
  ready() {
    return ajax.get("/readiness", null, null);
  },

  login(info) {
    loginId = info.loginId;
    loginPwd = info.loginPwd;
    return ajax.post("/auth/login", info, this.handleError);
  },
  mfAuth(authNum) {
    return ajax.post("/auth/mf-auth", authNum, this.handleError);
  },
  logout() {
    return ajax.get("/auth/logout");
  },
  handleError(error, reject) {
    if (axios.isCancel(error)) {
      notify.showError(error.message);
      reject(error);
    } else if (error.response) {
      const status = error.response.status;
      const data = error.response.data;
      console.log("status  - " + status + ", code = " + data.code);
      switch (status) {
        case 404:
          notify.showError("찾으시는 내용이 없습니다.");
          break;
        case 403:
          if (data.code === 3201 || data.code === 3209) {
            // router.push('/app/sessions/passwordChange/' + loginId + '/' + data.code)
            router
              .push({
                name: "passwordChangeOnLogin",
                params: {
                  loginId: loginId,
                  loginPwd: loginPwd,
                  errorCode: data.code,
                },
              })
              .then(() => {});
          } else {
            store.commit("setError", "로그인 실패: 관리자에게 문의 요망");
          }
          break;
        default:
          store.commit("setError", "[" + data.code + "]" + data.message);
      }
    } else if (error.request) {
      notify.showError("서버의 응답이 없습니다.");
    } else {
      notify.showError(error.message);
    }
  },
};
