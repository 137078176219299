/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import authenticate from "@/auth/authenticate";
import appIndex from "@/views/app/Index";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: appIndex,
      beforeEnter: authenticate,
      redirect: "/dashboard",

      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/ers/Dashboard"),
        },
        {
          path: "/request/:ersKey?",
          name: "request",
          props: true,
          component: () => import("@/views/ers/Request"),
        },
        {
          path: "/dispatch",
          component: () => import("@/views/ers/Dispatch"),
        },
        {
          path: "/dispatchMgmt/:ersKey?",
          name: "dispatchMgmt",
          props: true,
          component: () => import("@/views/ers/DispatchMgmt"),
        },
        {
          name: "RegisterTask",
          path: "/registerTask/:ersId?/:taskId?",
          props: true,
          component: () => import("@/views/ers/RegisterTask"),
        },
        {
          path: "/resultPhoto/:ersId/:taskId",
          props: true,
          component: () => import("@/views/ers/ResultPhoto"),
        },
        {
          name: "Result",
          path: "/result",
          props: true,
          component: () => import("@/views/ers/Result"),
        },
        {
          path: "/settlement",
          component: () => import("@/views/ers/Settlement"),
        },
      ],
    },
    {
      path: "/settings",
      component: appIndex,
      redirect: "/settings/work",
      children: [
        {
          path: "agent",
          component: () => import("@/views/ers/settings/AgentSetting"),
        },
        {
          path: "reset",
          component: () => import("@/views/ers/settings/ResetPassword"),
        },
        {
          path: "work",
          component: () => import("@/views/ers/settings/WorkSetting"),
        },
        {
          path: "agency",
          component: () => import("@/views/ers/settings/AgencySetting"),
        },
        {
          path: "car",
          component: () => import("@/views/ers/settings/CarSetting"),
        },
      ],
    },
    {
      path: "/app/sessions",
      component: () => import("@/views/app/sessions/Sessions"),

      children: [
        {
          path: "sign-in",
          name: "login",
          props: true,
          component: () => import("@/views/app/sessions/SignIn"),
        },
        {
          path: "mf-login",
          name: "MFLogin",
          component: () => import("@/views/app/sessions/MultiFactorLogin.vue"),
        },
        {
          path: "error",
          component: () => import("@/views/app/sessions/Error"),
        },
        {
          path: "passwordChange",
          name: "passwordChangeOnLogin",
          component: () => import("@/views/app/sessions/PasswordChange"),
          props: true,
        },
        {
          path: "/policy/location/:version?",
          name: "policyLocation",
          props: true,
          component: () => import("@/views/ers/policy/Location"),
        },
        {
          path: "/policy/privacy/:version?",
          name: "policyPrivacy",
          props: true,
          component: () => import("@/views/ers/policy/Privacy"),
        },
      ],
    },

    {
      path: "*",
      redirect: "/app/sessions/sign-in",
    },
  ],
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch("changeThemeLoadingState", true);
  }
  next();
});

router.afterEach(() => {
  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
});

export default router;
